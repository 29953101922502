<template>
  <v-navigation-drawer
    clipped
    temporary
    app
    right
    v-model="drawer"
    :permanent="drawer"
    width="600px"
  >
    <v-card-title>Produto</v-card-title>
    <v-card-text class="mb-6">
      <v-row>
        <v-col cols="4">
          <ProductSearchCode
            v-model="form.product"
            @input="setProduct($event)"
          />
        </v-col>
        <v-col>
          <ProductSearch
            v-model="form.product"
            @input="setProduct($event)"
            :showStock="true"
            :showGoogleSearch="true"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-chip-group
            v-if="form.product_variant"
            v-model="form.product_company_id"
            column
            :value-comparator="(a, b) => a.id === b.id"
          >
            <v-chip
              v-for="(productCompany, index) in form.product_variant.product_companies"
              v-show="productCompany.can_sell == 1"
              filter
              :color="colors[productCompany.company.id]"
              :key="index"
              :value="productCompany.id"
            >
              {{ productCompany.company.short_name }}
            </v-chip>
          </v-chip-group>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <app-number-field v-model="form.quantity" label="Quantidade" />
        </v-col>
        <v-col cols="8">
          <app-number-field v-model="form.price" label="Preço" readonly/>
        </v-col>
        <v-col cols="4">
          <app-number-field
            v-model="form.product_discount_percent"
            label="Desc Item (%)"
            @change="handleChangeDiscountPercent()"
          />
        </v-col>
        <v-col cols="4">
          <app-number-field
            v-model="form.product_discount_unity"
            label="Desc Item (R$)"
            @change="handleChangeDiscount()"
          />
        </v-col>
        <v-col cols="4">
          <app-number-field :value="netPrice" label="Preço Final" readonly />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-right" cols="4" offset="8">
          <div>
            <span class="text--disabled">Total:</span>
            <h2>{{ $format.decimal(netTotal) }}</h2>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <template v-slot:append>
      <div class="pa-4" style="background-color: #f5f5f5">
        <v-btn
          class="text-capitalize mr-2"
          color="primary"
          @click="handleConfirm()"
          >{{ btnText }}</v-btn
        >
        <v-btn @click="drawer = false" text class="text-capitalize"
          >Cancelar</v-btn
        >
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>

import ProductSearch from "@/components/product/ui/ProductSearch.vue";
import ProductSearchCode from "@/components/product/ui/ProductSearchCode.vue";

export default {
  components: { ProductSearch, ProductSearchCode },

  data() {
    return {
      drawer: false,

      editIndex: null,

      form: {
        product_company_id: null,
      },

      colors: {
        1: "#012852",
        2: "#ED3237",
        3: "#F58634",
        4: "#FFF212",
        5: "#A8CF45",
        6: "#00AFEF",
      },

      baseForm: {
        product: null,
        product_variant: null,
        product_company_id: null,
        product_company: null,
        unit: null,
        quantity: 1,
        price: 0,
        price_cash: 0,
        price_forward: 0,
        price_profit: 0,
        //product_total: 0,
        product_discount_unity: 0,
        product_discount: 0,
        product_discount_percent: 0,
        shipping : 0,
        expenses : 0,
        //discount: 0,
        //net_total: 0,
      },
    };
  },

  computed: {
    saleProducts() {
      return this.$store.state.saleForm.saleForm.sale_products;
    },

    productCompanyId() {
      return this.form.product_company_id;
    },

    productDiscount() {
      return this.$calc.round(this.form.quantity * this.form.product_discount_unity)
    },

    productTotal() {
      return this.$calc.round(this.form.quantity * this.form.price)
    },

    netPrice() {
      return this.$calc.round(this.form.price - this.form.product_discount_unity);
    },

    netTotal() {
      return this.$calc.round(this.form.quantity * this.netPrice);
    },

    // avaliableProductCompanies() {
    //   return this.form.product_variant.product_companies.filter(row => row.can_sell == 1);
    // }
    btnText() {
      return this.editIndex == null ? "Adicionar" : "Atualizar";
    },
  },

  created() {
    this.reset();
  },

  watch: {
    productCompanyId() {
      if (this.productCompanyId) {
        this.setProductCompany();
      }
    },
  },

  methods: {
    addSaleProduct() {
      this.reset();
      this.drawer = true;
    },

    editSaleProduct(index) {
      this.reset();
      this.editIndex = index;
      this.setFormData(index);
      this.drawer = true;
    },

    reset() {
      this.editIndex = null;
      this.form = JSON.parse(JSON.stringify(this.baseForm));
    },

    setFormData(index) {
      this.form = JSON.parse(JSON.stringify(this.saleProducts[index]));
    },

    setProduct(product) {
      this.form.product_variant = product.product_variants[0];

      this.form.price = product.product_variants[0].price_forward;
      this.form.price_cash = product.product_variants[0].price_cash;
      this.form.price_forward = product.product_variants[0].price_forward;

      this.form.product_company_id = null;
    },

    setProductCompany() {
      let productCompany = this.form.product_variant.product_companies.find(
        (row) => row.id == this.form.product_company_id
      );

      this.form.product_company = productCompany;
    },

    isValid() {
      if (!this.form.product_company_id) {
        this.$snackbar({
          message: "Favor selecionar uma empresa",
          props: {
            color: "error",
            timeout: 1000,
          },
        });
        return false;
      }

      return true;
    },

    async handleConfirm() {
      if (!this.isValid()) {
        return false;
      }

      if (this.editIndex !== null) {
        this.updateSaleProduct();
      } else {
        this.storeSaleProduct();
      }
    },

    calcTotals() {
      this.form.product_total = this.productTotal
      this.form.product_discount = this.productDiscount
      this.form.sub_total = this.$calc.round(this.productTotal - this.productDiscount)
    },

    updateSaleProduct() {
      this.calcTotals();

      this.$store.commit("saleForm/updateProduct", {
        saleProduct: this.form,
        index: this.editIndex,
      });

      this.drawer = false;
    },

    storeSaleProduct() {
      this.calcTotals();
      
      this.$store.commit("saleForm/addProduct", this.form);

      this.reset();
    },

    handleChangeDiscount() {
      let result = this.$calc.round(
        (this.form.product_discount_unity * 100) / this.form.price
      );

      this.$set(this.form, 'product_discount_percent', result)
    },

    handleChangeDiscountPercent() {
      let result = this.$calc.round(
        this.form.price * (this.form.product_discount_percent / 100)
      );

      this.$set(this.form, "product_discount_unity", result);
    },
  },
};
</script>

<style>
</style>
<template>
  <div>
    <template v-if="selectedValue">
      <app-text-field
        :label="label"
        :value="selectedValue.name"
        readonly
        @click="open()"
        :rules="rules"
        prepend-inner-icon="mdi-magnify"
        @keypress="open()"
        :append-icon="selectedValue.not_registered == 1 ? 'mdi-plus' : ''"
        @click:append="openSupplierForm()"
        v-bind="$attrs"
        v-on="$listeners"
      />
    </template>
    <template v-else>
      <div id="acc" @click.stop="open()">
        <app-text-field
          :label="label"
          readonly
          @click="open()"
          v-bind="$attrs"
          v-on="$listeners"
          prepend-inner-icon="mdi-magnify"
        />
      </div>
    </template>

    <v-dialog
      v-model="dialog"
      max-width="950px"
      persistent
      scrollable
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card>
        <v-card-title>
          <div class="d-flex justify-space-between" style="width: 100%">
            <div>
              <span>Selecionar {{ label }}</span>
            </div>
          </div>
        </v-card-title>
        <v-card-subtitle>
          <v-text-field
            :loading="isLoading"
            class="mt-4"
            v-model="searchForm.text"
            autofocus
            solo
            flat
            background-color="grey lighten-4"
            prepend-inner-icon="mdi-magnify"
            placeholder="Pesquisar..."
            hide-details
            v-debounce:300ms="searchText"
          />
        </v-card-subtitle>
        <v-card-text>
          <v-data-table
            ref="productTable"
            @click:row="($event, { item }) => setSelectedValue(item)"
            :headers="headers"
            :items="products.data"
            :items-per-page="products.per_page"
            hide-default-footer
            disable-sort
            dense
          >
            <template v-slot:[`item.name`]="{ item }">
              <app-icon
                color="primary"
                v-if="showGoogleSearch"
                small
                @click.stop="searchGoogle(item.name)"
              >
                travel_explore
              </app-icon>

              {{ item.name }}
              <small class="text--secondary">
                #{{ item.code }} | w{{ item.product_variants[0].webcom_id }}
              </small>
            </template>

            <template v-slot:[`item.price_cash`]="{ item }">
              {{ $format.decimal(item.product_variants[0].price_cash) }}
            </template>

            <template v-slot:[`item.price_forward`]="{ item }">
              {{ $format.decimal(item.product_variants[0].price_forward) }}
            </template>

            <template v-slot:expanded-item="{ item }">
              <td @click="setSelectedValue(item)" colspan="99">
                <v-simple-table class="expanded-table" dense>
                  <template v-slot:default>
                    <tbody>
                      <template
                        v-for="(product_company, index) in item
                          .product_variants[0].product_companies"
                      >
                        <tr :key="index" v-if="showStockLine(product_company)">
                          <td class="px-0" style="width: 10%">
                            <small>
                              {{ product_company.company.short_name }}
                            </small>
                          </td>
                          <td class="px-0" style="width: 10%">
                            <ProductStockLabel
                              :productCompany="product_company"
                            />
                          </td>
                          <td class="px-0" style="width: 10%">
                            <v-icon small left>
                              mdi-package-variant-closed-remove
                            </v-icon>
                            <small>
                              {{
                                $format.decimal(product_company.reserved_stock)
                              }}
                            </small>
                          </td>
                          <td class="px-0" style="width: 10%">
                            <v-icon small left>mdi-dolly</v-icon>
                            <small>
                              {{
                                $format.decimal(
                                  product_company.expedition_stock
                                )
                              }}
                            </small>
                          </td>
                          <td class="px-0" style="width: 10%">
                            <v-icon small left> mdi-view-grid-outline </v-icon>
                            <small>
                              {{
                                $format.decimal(product_company.physic_stock)
                              }}
                            </small>
                          </td>
                          <td class="px-0" style="width: 10%">
                            <v-icon small left> mdi-truck-fast-outline </v-icon>
                            <small>
                              {{
                                $format.decimal(product_company.transfer_stock)
                              }}
                            </small>
                          </td>
                          <td />
                        </tr>
                      </template>
                    </tbody>
                  </template>
                </v-simple-table>
              </td>
            </template>
          </v-data-table>
          <app-pagination @click="search($event)" :data="products" />
        <!-- <v-list>
            <template v-for="(row, index) in searchResults.data">
              <v-list-item :key="index" @click="setSelectedValue(row)">
                <v-list-item-content>
                  <v-list-item-title>{{ row.name }} </v-list-item-title>

                </v-list-item-content>
                <v-list-item-icon> </v-list-item-icon>
              </v-list-item>
              <v-divider :key="'div' + index" />
            </template>
          </v-list>-->
        </v-card-text>
        <v-card-actions>
          <v-btn class="text-capitalize" text @click="dialog = false"
            >Voltar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ProductStockLabel from "@/components/product/ui/ProductStockLabel.vue";

export default {
  components: { ProductStockLabel },
  props: {
    value: {},
    showStock: {
      type: Boolean,
      default: false,
    },
    showGoogleSearch: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    label: "Produto",
    url: "product/product",

    searchResults: {
      data: [],
    },

    selectedValue: {
      name: null,
    },

    dialog: false,

    isLoading: false,

    searchForm: {
      text: null,
      status : [1],
      classificacao_fiscal : '00'
    },

    products: {},

    headers: [
      {
        text: "Descrição",
        align: "start",
        value: "name",
      },
      {
        text: "Marca",
        align: "start",
        value: "brand.name",
        width: "15%",
      },
      {
        text: "Preço Av",
        value: "price_cash",
        align: "right",
        width: "10%",
      },
      {
        text: "Preço Pz",
        value: "price_forward",

        align: "right",
        width: "10%",
      },
    ],
  }),

  watch: {
    value() {
      this.selectedValue = this.value;
    },
  },

  created() {
    this.selectedValue = this.value;
  },

  computed: {
    rules() {
      return this.$attrs.rules ? this.$attrs.rules : [];
    },
  },
  methods: {
    async open() {
      await this.search();
      this.searchForm.text = null;
      this.dialog = true;
    },

    async search(page) {
      this.searchForm.page = page;

      this.isLoading = true;

      await this.$http.index(this.url, this.searchForm).then((response) => {
        this.products = response.products;
        
        if (this.showStock) {
          for (let product of this.products.data) {
            this.$set(this.$refs.productTable.expansion, product.id, true);
          }
        }

          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    searchText() {
      this.search();
    },

    setSelectedValue(selectedValue) {
      this.selectedValue = selectedValue;

      this.$emit("input", selectedValue);

      this.dialog = false;
    },

    clear() {
      this.selectedValue = null;
    },

    showStockLine(productCompany) {
      if (
        productCompany.available_stock != 0 ||
        productCompany.reserved_stock != 0 ||
        productCompany.expedition_stock != 0 ||
        productCompany.physic_stock != 0 ||
        productCompany.transfer_stock != 0
      ) {
        return true;
      }

      if (productCompany.can_sell == 1) {
        return true;
      }

      return false;
    },

    searchGoogle(product_name) {
      window.open("https://www.google.com/search?q=" + product_name, "_blank");
    },
  },
};
</script>

<style  >
.v-data-table__expanded__content {
  box-shadow: none !important;
  background-color: #f5f5f5;
}

.expanded-table {
  background-color: #f5f5f5 !important;
}

.expanded-table td {
  border-bottom: red !important;
}
</style>

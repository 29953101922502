<template>
  <div>
    <template v-if="selectedValue">
      <app-text-field
        :label="label"
        :value="selectedValue.name"
        readonly
        v-bind="$attrs"
        v-on="$listeners"
        :disabled="disabled"
        @click="open()"
        :prepend-inner-icon="iconSearch"
        @keypress="open()"
      />
    </template>
    <template v-else>
      <div id="acc" @click.stop="open()">
        <app-text-field
          :label="label"
          readonly
          v-bind="$attrs"
          v-on="$listeners"
          @click="open()"
          :disabled="disabled"
          prepend-inner-icon="mdi-magnify"
        />
      </div>
    </template>

    <v-dialog
      v-model="dialog"
      max-width="600px"
      scrollable
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card>
        <v-card-title>
          <div class="d-flex justify-space-between" style="width: 100%">
            <div>
              <span>Selecionar {{ label }}</span>
            </div>
          </div>
        </v-card-title>
        <v-card-subtitle>
          <v-text-field
            :loading="isLoading"
            class="mt-4"
            v-model="searchForm.text"
            autofocus
            solo
            flat
            background-color="grey lighten-4"
            prepend-inner-icon="mdi-magnify"
            placeholder="Pesquisar..."
            hide-details
            v-debounce:300ms="search"
          />
          <template v-if="hidePositionFilter">
            <br />
            <v-chip-group
              @change="search()"
              v-model="searchForm.position"
              column
              multiple
            >
              <v-chip
                v-for="position in positions"
                :key="position.value"
                :value="position.value"
                filter
                color="primary"
              >
                {{ position.text }}
              </v-chip>
            </v-chip-group>
          </template>
        </v-card-subtitle>
        <v-card-text>
          <v-list>
            <template v-for="(row, index) in searchResults.data">
              <v-list-item :key="index" @click="setSelectedValue(row)">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ row.code }} - {{ row.name }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon> </v-list-item-icon>
              </v-list-item>
              <v-divider :key="'div' + index" />
            </template>
          </v-list>
          <v-pagination
            v-model="searchForm.page"
            :length="searchResults.last_page"
            @input="search($event)"
            :total-visible="5"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn class="text-capitalize" text @click="dialog = false">
            Voltar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PositionSelect from "@/components/employees/ui/PositionSelect.vue";

export default {
  components: { PositionSelect },
  props: {
    value: {},
  },

  data: () => ({
    label: "Vendedor",
    url: "employee/employee",

    searchResults: {
      data: [],
    },

    selectedValue: {
      name: null,
    },

    dialog: false,

    isLoading: false,

    searchForm: {
      text: null,
      status: 1,
      position: ["vendedor", "gerente"],
    },
    positions: [
      { text: "Vendedor", value: "vendedor" },
      { text: "Gerente", value: "gerente" },
    ],
  }),

  watch: {
    value() {
      this.selectedValue = this.value;
    },
  },

  created() {
    this.selectedValue = this.value;
  },
  computed: {
    employee() {
      return this.$store.state.auth.userData.employee;
    },

    iconSearch() {
      return this.disabled ? "mdi-account" : "mdi-magnify";
    },

    disabled() {
      return this.$attrs.disabled || false;
    },
    hidePositionFilter() {
      return !this.$attrs.hidePosition || false;
    },
  },
  methods: {
    async open() {
      await this.search();

      this.dialog = true;
    },

    async search() {
      this.isLoading = true;

      await this.$http.index(this.url, this.searchForm).then((response) => {
        this.searchResults = response.employees;
      });

      this.isLoading = false;
    },

    setSelectedValue(selectedValue) {
      this.selectedValue = selectedValue;

      this.$emit("input", selectedValue);

      this.dialog = false;
    },

    clear() {
      this.selectedValue = null;
    },
  },
};
</script>

<template>
  <div>
    <v-row>
      <v-col>
        <v-icon left>{{ operation.icon }} </v-icon>
        {{ operation.description }}
        <template v-if="id">#{{ number }}</template>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="8">
        <v-row>
          <v-col cols="8">
            <CustomerSearch v-model="customer" clearable />
            <CustomerDetailsSection :customer="customer" />
            <v-alert
              v-if="customer && customer.alert_notes"
              border="left"
              type="info"
              dense
              outlined
            >
              {{ customer.alert_notes }}
            </v-alert>
          </v-col>
        </v-row>
        <v-row v-if="canEditDeliveryType">
          <v-col>
            Tipo de Entrega:
            <v-chip-group column v-model="delivery_type">
              <v-chip filter :value="'no_delivery'" color="primary">
                Venda balcão
              </v-chip>
              <v-chip filter :value="'delivery'">Entrega Futura</v-chip>
            </v-chip-group>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <SaleProductsList
              @editSaleProduct="handleEditSaleProduct($event)"
              @addProduct="handleAddProduct()"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4">
        <v-row>
          <v-col>
            <span v-if="salesman">
              <v-icon left>mdi-account</v-icon> {{ salesman.name }}
            </span>
            <br />
            <span v-if="company">
              <v-icon left>mdi-domain</v-icon> {{ company.name }}
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <SaleTotals @store="handleStore($event)" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <SaleProductForm ref="SaleProductForm" />
    <SaleSuccessDialog ref="SaleSuccessDialog" @newSale="newSale()" />
  </div>
</template>

<script>
import CustomerDetailsSection from "../../customer/sections/CustomerDetailsSection.vue";
import CompanySelect from "../../company/ui/CompanySelect";
import SaleTotals from "../sections/SaleTotals.vue";
import SaleProductForm from "../forms/SaleProductForm.vue";
import CustomerSearch from "../../customer/ui/CustomerSearch";
import SaleProductsList from "../sections/SaleProductsList";
import SaleSuccessDialog from "../sections/SaleSuccessDialog.vue";
import SalesmanSearch from "@/components/employees/ui/SalesmanSearch.vue";

export default {
  components: {
    CompanySelect,
    CustomerSearch,
    SaleProductsList,
    SaleTotals,
    SaleProductForm,
    SaleSuccessDialog,
    CustomerDetailsSection,
    SalesmanSearch,
  },

  props: {
    id: {},
    operation: {},
    // movement_stock: {},
    // movement_stock_type: {},
    // movement_finance: {},
    // movement_finance_type: {},
  },

  data: () => ({
    saleProductEditIndex: null,
  }),

  mounted() {
    this.newSale();
  },

  computed: {
    number() {
      return this.$store.state.saleForm.saleForm.number;
    },
    canSalesOtherUser() {
      return !this.$acl.can("salesOtherUser");
    },

    company: {
      get() {
        return this.$store.state.saleForm.saleForm.company;
      },
      set(value) {
        this.$store.commit("saleForm/setCompany", value);
      },
    },

    customer: {
      get() {
        return this.$store.state.saleForm.saleForm.customer;
      },
      set(value) {
        this.$store.commit("saleForm/setCustomer", value);
      },
    },

    salesman: {
      get() {
        return this.$store.state.saleForm.saleForm.salesman;
      },
      set(value) {
        this.$store.commit("saleForm/setSalesman", value);
      },
    },

    delivery_type: {
      get() {
        return this.$store.state.saleForm.saleForm.delivery_type;
      },
      set(value) {
        this.$store.commit("saleForm/setDeliveryType", value);
      },
    },

    status: {
      get() {
        return this.$store.state.saleForm.saleForm.status;
      },
      set(value) {
        this.$store.commit("saleForm/setStatus", value);
      },
    },
    canEditDeliveryType() {
      return this.operation.movement_stock_type == "out";
    },
  },

  methods: {
    newSale() {
      this.reset();
      this.setFormData();
    },

    reset() {
      this.$store.commit("saleForm/reset");
    },

    setFormData() {
      this.$store.commit("saleForm/setOperation", this.operation);

      let employee = JSON.parse(
        JSON.stringify(this.$store.state.auth.userData.employee)
      );

      this.salesman = employee;
      this.company = this.salesman.company;

      if (this.operation.movement_stock_type == "in") {
        this.delivery_type = "no_delivery";
      }
    },

    handleAddProduct() {
      this.$refs.SaleProductForm.addSaleProduct();
    },

    handleEditSaleProduct(index) {
      this.$refs.SaleProductForm.editSaleProduct(index);
    },

    async handleStore(status) {
      this.status = status.status;

      this.$loading.start();
      this.$store
        .dispatch("saleForm/storeSale")
        .then((response) => {
          this.$loading.finish();
          this.$refs.SaleSuccessDialog.open(response.number);
        })
        .catch((error) => {
          this.$loading.finish();
        });

      // this.$store.dispatch("saleForm/storeSale").then(response => {

      //   this.$loading.finish()
      //   this.$refs.SaleSuccessDialog.open()

      // }).catch(error => {
      //   this.$loading.finish()
      // });
    },
  },
};
</script>

<style></style>
